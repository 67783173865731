.weather {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: #dbca2e;
  padding: 20px;
}

.weatherPic:hover {
  transform: scale(1.1);
}

@media (width < 420px) {
  .weather h2 {
    font-size: 1.4rem;
  }
}

@media (width < 400px) {
  .weather h2 {
    font-size: 1.2rem;
  }
}

@media (width < 350px) {
  .weather h2 {
    font-size: 1.1rem;
  }
}
